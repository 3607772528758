<template>
    <div class="Home">

      <page-header/>

        <div class="uk-container">
          <!-- <section class="uk-section uk-padding-remove uk-margin-bottom">
            <div class="uk-container uk-margin-remove-left" uk-grid>
            <div class="uk-table uk-table-responsive uk-width-1-1@m  uk-margin-left uk-table-small">
              <colgroup >
                <col width="15%">
                <col width="38%">
                <col width="15%">
                <col width="45%">
                <col width="15%">
              </colgroup>
              <tr >
                <th >
                  <span class="uk-text-capitalize">{{$t("studentExams.date")}} :</span>
                </th>
                <th >
                    <date-picker
                        class="date-picker"
                        v-model="examDate"
                        :lang="lang"
                        placeholder ="start date"
                      ></date-picker>
                    <span uk-icon="triangle-right"></span>
                    <date-picker
                      class="date-picker"
                      v-model="examDateEnd"
                        :lang="lang"
                        placeholder ="end date"
                      ></date-picker>
                </th>
                <th ><span class="uk-text-capitalize">{{$t("studentExams.eType")}}  :</span></th>
                <th >
                  <div class="uk-margin uk-grid-small uk-margin-remove" uk-grid>
                      <label>
                        <input class="uk-radio uk-margin-left uk-text-capitalize" type="radio"
                         name="radio"/> {{$t("studentExams.all")}}
                      </label>
                      <label>
                        <input class="uk-radio uk-text-capitalize" type="radio" name="radio"/>
                        {{$t("studentExams.paid")}}
                      </label>
                      <label>
                        <input class="uk-radio uk-text-capitalize" type="radio" name="radio"/>
                        {{$t("studentExams.free")}}
                      </label>
                    </div>
                </th>
              </tr>
              <tr >
                <th ><span class="uk-text-capitalize">{{$t("studentExams.orga")}} :</span> </th>
                <th >
                  <div class="uk-width-expand">
                    <select id="form-select" class="uk-select uk-form-small uk-width-1-1">
                      <option>Select ..</option>
                      <template v-for="(item, index) in organizs" >
                        <option :value="item.id" :key="index">{{item.organizName}}</option>
                      </template>
                    </select>
                  </div>
                </th>
                <th ><span class="uk-text-capitalize">{{$t("studentExams.eName")}} </span></th>
                <th  style="width: 378px;">
                  <input v-model="inputval" class="uk-input uk-form-small" type="text"/>
                </th>
                <th >
                  <button type="button" class="uk-button uk-button-primary uk-text-capitalize">{{$t("studentExams.search")}} </button>
                </th>
              </tr>
            </div>
            </div>
          </section> -->
        <section class="uk-section uk-padding-remove uk-margin-left">
            <div class="uk-container-large uk-margin-remove-left uk-padding-remove" uk-grid>
                <div class="uk-width-1-4 uk-margin-top uk-padding-remove">
                  <div class="uk-card uk-card-primary">
                    <div class="uk-card-title uk-padding-small-top uk-padding-small-small">
                      <span class="uk-text-capitalize">{{$t("studentExams.eType")}}</span>
                    </div>
                    <div class="uk-card-body uk-padding-small">
                      <label>
                        <input class="uk-radio uk-text-capitalize" type="radio"
                         name="radio"/> {{$t("studentExams.all")}}
                      </label>
                      <label class="uk-margin-left">
                        <input class="uk-radio uk-text-capitalize" type="radio" name="radio"/>
                        {{$t("studentExams.paid")}}
                      </label>
                      <label class="uk-margin-left">
                        <input class="uk-radio uk-text-capitalize" type="radio" name="radio"/>
                        {{$t("studentExams.free")}}
                      </label>
                    </div>

                    <!-- orga day -->
                    <div class="uk-card-title uk-padding-small-top uk-padding-small-small">
                      <span class="uk-text-capitalize">{{$t("studentExams.date")}}</span>
                    </div>
                    <div class="uk-card-body uk-padding-small">
                        <div >
                          <date-picker
                          class="date-picker"
                          v-model="examDate"
                          :lang="lang"
                          placeholder ="start date"
                          ></date-picker>
                        </div>
                        <div >
                          <span uk-icon=""></span>
                        </div>
                        <div >
                          <date-picker
                            class="date-picker"
                            v-model="examDateEnd"
                              :lang="lang"
                              placeholder ="end date"
                            ></date-picker>
                        </div>
                    </div>


                    <!-- orga select -->
                    <div class="uk-card-title uk-padding-small-top uk-padding-small-small">
                      <span class="uk-text-capitalize">{{$t("studentExams.orga")}}</span>
                    </div>
                    <div class="uk-card-body uk-padding-small">
                      <div class="uk-width-expand">
                        <select id="form-select" class="uk-select uk-form-small uk-width-1-1">
                          <option>Select ..</option>
                          <template v-for="(item, index) in organizs" >
                            <option :value="item.id" :key="index">{{item.organizName}}</option>
                          </template>
                        </select>
                      </div>
                    </div>

                    <!-- orga name -->
                    <div class="uk-card-title uk-padding-small-top uk-padding-small-small">
                      <span class="uk-text-capitalize">{{$t("studentExams.eName")}}</span>
                    </div>
                    <div class="uk-card-body uk-padding-small">
                      <input v-model="inputval" class="uk-input uk-form-small" type="text"
                      placeholder="Write name"/>
                    </div>

                    <div class="uk-card-body uk-padding-small uk-flex uk-flex-center">
                        <button type="button" class="uk-button uk-button-primary uk-text-capitalize">{{$t("studentExams.search")}} </button>
                    </div>

                  </div>
                </div>
                <div class="uk-width-3-4" uk-filter="target: .js-filter">
                  <ul class="uk-subnav uk-subnav-pill">
                      <li class="uk-active" uk-filter-control><a href="#" class="uk-text-bold uk-text-normal">ALL</a></li>
                      <li uk-filter-control="[data-tags*='school']"><a href="#" class="uk-text-bold uk-text-normal">University Exams</a></li>
                      <li uk-filter-control="[data-tags*='organization']"><a href="#" class="uk-text-bold uk-text-normal">Academy exams</a></li>
                      <li uk-filter-control="[data-tags*='partners']"><a href="#" class="uk-text-bold uk-text-normal">Education Center exams</a></li>
                      <!-- <router-link class="uk-button uk-button-default uk-margin-left"
                            title="Exam register" tag="a" :to="{name: 'StudentRegisterExam'}"> Register exam
                      </router-link> -->
                  </ul>
                  <ul class="js-filter uk-child-width-1-3 uk-child-width-1-4@m uk-text-center" uk-grid>
                    <li data-tags="school" v-for="(item, index) in allExamList" :key="index">
                        <div class="uk-card uk-card-default uk-box-shadow-medium" id="card">
                            <div class="uk-card-media-top">
                              <img src="https://dcassetcdn.com/design_img/2505971/184588/184588_13539117_2505971_b4195255_image.png" alt="" style="height: 160px; width: 200px;"
                               class="uk-padding uk-padding-remove-bottom uk-padding-top uk-border-circle">
                                <!-- <img :src="item.organizLogo" alt="" style="height: 160px; width: 200px;" class="uk-padding uk-padding-remove-bottom uk-padding-top"> -->
                            </div>
                            <div class="uk-card-body uk-padding-small">
                                <h5 class="uk-text-bold uk-margin-remove-top uk-margin-small-bottom uk-width-expand
                                  uk-text-center" style="color: black;">{{item.examName.substring(0,17)}}</h5>
                                <p class="uk-mname: 'Page2',argin-remove-top uk-text-center uk-margin-remove-bottom">
                                  {{$t("studentExams.status")}} : <label  class="uk-label uk-text-lowercase" v-if="item.examStatus == 'start'">{{item.examStatus}}</label>
                                <label  class="uk-label-danger" v-else style="padding-left: 6px; padding-right: 6px">{{item.examStatus}}</label></p>
                            </div>
                              <div class="uk-subnav uk-flex uk-flex-center" uk-grid>
                                <button type="button" v-on:click="ExamCodeRequest(item.examRoundId)" class="uk-button uk-button-danger uk-button-small uk-margin-left uk-margin-bottom">register</button>
                            </div>
                        </div>
                    </li>
                  </ul>
                </div>
            </div>
        </section>
        </div>

        <page-footer/>

    </div>

</template>


<script scoped>
  import StudentService from '@/services/StudentService'
  import DatePicker from "vue2-datepicker";
  import 'vue2-datepicker/index.css';
  const PageHeader = () => import("./StudentHeaderNew.vue");
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    name: 'StudentsExams',
    components: {
      DatePicker,
      PageHeader,
      PageFooter
    },
    created() {
      this.StudentHomeData()
      this.AllExamList()
    },
    data() {
      return {
        id:0,
        error: null,
        exams: null,
        allExamList: [],
        latestexams: null,
        myOrganizCount: null,
        organizs: [],
        myorganizs: [],
        myExamCount: null,
        userexams: null,
        items: null,
        content: null,
        inputval: '',
        message: '',
        examDate: "",
        examDateEnd: "",
        lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        placeholder: {
          dateRange: "Start date ~ End date"
        }
      },
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            this.time3 = [new Date(),new Date()];
          }
        }
      ],
      timePickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:30"
      }
      }
    },
    methods: {

      async StudentHomeData(){
         try {
          const response = await StudentService.StudentHomeData({

          })
          this.latestexams = response.data.result.latestexams
          this.userexams = response.data.result.userexams
          this.organizs = response.data.result.organizs
          this.myorganizs = response.data.result.myorganizs

          console.log("StudentHomeData")
          console.log(response.data)
        } catch (error) {
          console.log(error)
        }
      },
      async ExamCodeRequest(examRoundId) {
        try {
          console.log("Exam code request "+examRoundId)
          const response = await StudentService.ExamCodeRequest({
            examId:examRoundId
          })

          if (response.data.status == 200) {
            if (response.data.success) {
              alert("success alert "+ response.data.message);
            }else{
          console.log("warning alert "+response.data.message);
            }
          }
          console.log("ExamCodeRequest")
          console.log(response.data)
        } catch (error) {
          console.log(error.response.data.message)
        }
      },
      async AllExamList() {
        try {
          const response = await StudentService.AllExamList({
            examName: this.inputval
          })
          this.allExamList = response.data.content
          console.log("AllExamList")
          console.log(response.data)
        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>

<style scoped>
.date-picker{
  width: 245px;
  height: 0px;
}
#divider {
border-style: solid;
border-width: 0.4px;
color: rgb(219, 219, 224);
border-top-color: rgb(175, 173, 175) !important;
width: 100%;
}
.uk-search{
    border: 1px solid;
    border-radius: 8px;
}
.uk-card-title{
  color: #666;
  font-size: large;
  font-weight: 600;
  padding-left: 19px;
}
.uk-card-primary{
  background:#fff;
  color: #666;
  border-radius: 15px;
  padding: 10px;
  margin-top: 30px;
}
.uk-card-default{
border:1px solid;
border-color: #8f8fcf;
border-radius: 15px;

}
.uk-card-default:hover{
  border: 1px solid;
  border-color: #01B0FF;
  border-radius: 15px;
}
</style>
